/* `pointer-events: none` isn't supported by IE11, setting `cursor: default` just makes it appear less like a link */
.pe-none {
  cursor: default;
}

.text-break {
  word-wrap: break-word !important;
}

.user-select-none {
  -ms-user-select: none !important;
}

.user-select-auto {
  -ms-user-select: auto !important;
}
